import { api, param2query } from "./api.service";

export const getAll = (params, cancelToken) => {
  return api.get("motivoglosa/listar?" + param2query(params), cancelToken);
};

export const getAllMotivoGlosa = (id) => {
  return api.get("motivoglosa/listar-motivoglosa/" + id);
};

export const getMotivoGlosa = (id) => {
  return api.get("motivoglosa/" + id);
};

export const criarMotivoGlosa = (model) => {
  return api.post("motivoglosa", model);
};

export const atualizarMotivoGlosa = (model) => {
  return api.put("motivoglosa", model);
};

export const pesquisarMotivoGlosa = (query) => {
  return api.get("motivoglosa/buscar-descricao?filtro=" + query);
};

export default { getAll };
