var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.$route.meta.title,
          items: _vm.$route.meta.breadcrumbs
        }
      }),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _vm.$slots.dropdownItens
              ? _c("div", { staticClass: "d-flex justify-content-end" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "float-end",
                          attrs: {
                            variant: "white",
                            right: "",
                            "toggle-class": "font-size-16 text-body p-0"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function() {
                                  return [
                                    _c("span", { staticClass: "ml-3" }, [
                                      _c("i", {
                                        staticClass: "mdi mdi-dots-vertical"
                                      })
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            344907657
                          )
                        },
                        [_vm._t("dropdownItens")],
                        2
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._t("default")
          ],
          2
        ),
        _vm.$slots.footer
          ? _c("div", { staticClass: "card-footer" }, [_vm._t("footer")], 2)
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }