<template>

  <Layout>
    <PageHeader :title="$route.meta.title" :items="$route.meta.breadcrumbs" />

    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-end" v-if="$slots.dropdownItens">
          <div>

            <b-dropdown
              class="float-end"
              variant="white"
              right
              toggle-class="font-size-16 text-body p-0"
            >
              <template #button-content>
                <span class="ml-3">
                  <i class="mdi mdi-dots-vertical"></i>
                </span>
              </template>
              <slot name="dropdownItens" />
            </b-dropdown>

          </div>
        </div>

        <slot />
        
      </div>

      <div class="card-footer" v-if="$slots.footer">
        <slot name="footer" />
      </div>

</div>
  </Layout>

</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
export default {
  name: "CardList",
  components: {
    Layout, PageHeader
  },
  props: {
  }
};
</script>
